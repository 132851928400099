import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { splitFirstWord } from '@/modules/tariff/shared/helpers';
import {
  EmailIcon,
  EmailPurple,
  GlobeIcon,
  GlobePurple,
  WhitePhoneIcon,
  PhonePurple,
  TV,
  TVPurple,
  WiFi,
  WiFiPurple,
  // PlugIcon,
  // PlugIconPurple,
  FamilyHome,
  FamilyHomePurple,
  RouterIcon,
  RouterIconPurple,
  RubIcon,
  PhoneTariff,
  InfinityIcon,
  InfinityIconWhite,
  InfinitySmallIcon,
  InfinitySmallPurpleIcon,
  SimcardOption,
} from '@/shared/assets/svg';
import { TariffCardOptionProps } from './tariff-card-option.types';
import styles from './tariff-card-option.module.scss';

const icons: Record<TariffCardOptionProps['icon'], ReactNode> = {
  phone: <WhitePhoneIcon />,
  email: <EmailIcon />,
  globe: <GlobeIcon />,
  tv: <TV />,
  speed: <WiFi />,
  // connection: <PlugIcon />,
  connection: <FamilyHome />,
  router: <RouterIcon />,
  rub: <RubIcon />,
  phoneTariff: <PhoneTariff />,
  sim: <SimcardOption />,
  unlimited: <InfinitySmallIcon />,
};

const purpleIcons: Record<TariffCardOptionProps['icon'], ReactNode> = {
  phone: <PhonePurple />,
  email: <EmailPurple />,
  globe: <GlobePurple />,
  tv: <TVPurple />,
  speed: <WiFiPurple />,
  // connection: <PlugIconPurple />,
  connection: <FamilyHomePurple />,
  router: <RouterIconPurple />,
  rub: <RubIcon />,
  phoneTariff: <PhoneTariff />,
  sim: <SimcardOption />,
  unlimited: <InfinitySmallPurpleIcon />,
};

const TariffCardOption: FC<TariffCardOptionProps> = ({ icon, value, text, from, purple, className, boldFistWord }) => {
  const infinityIcon = purple ? <InfinityIcon /> : <InfinityIconWhite />;

  return (
    <div className={cn(styles.container, { [styles.purple]: purple })}>
      {purple ? purpleIcons[icon] : icons[icon]}
      <div className={styles.row}>
        {from && <span className={styles.priceFrom}>от </span>}
        {value && (
          <span className={cn(styles.numberOption, { [styles.numberOption__unlimited]: icon === 'unlimited' })}>
            {value === '∞' ? infinityIcon : value}
          </span>
        )}
        {text && (
          <span className={cn(styles.textOption, className)}>
            {boldFistWord && <span className={styles.textOption__bold}> {splitFirstWord(text)[0]} </span>}
            {boldFistWord ? splitFirstWord(text)[1] : text}
          </span>
        )}
      </div>
    </div>
  );
};
export default TariffCardOption;
