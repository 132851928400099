import { SingleMedia, StrapiCollectionResponse } from '@/shared/types/strapi.types';
import { ServiceEntity } from '@/store/api/service/service.type';

export const EVPATORIA_NAME = 'evpatoria';

export const MOYA_STRANA = {
  NAME: 'moya-strana',
  LOCALIZED_NAME: 'Моя страна',
};

export interface AccordionDataType {
  id: number;
  title: string;
  subtitle: string;
  items: AccordionItemsType[];
}

export interface AccordionItemsType {
  id: number;
  price: string;
  text: string;
  desc: string;
}

export interface Prices {
  convergent_price?: number | null;
  mono_price?: number | null;
  promotion_price?: number | null;
  evpatoria_price?: number | null;
  price_apartment?: number | null;
  price_house?: number | null;
}

export interface TariffType {
  name: string;
  localized_name: string;
  background_image: SingleMedia;
  text: string;
  services: StrapiCollectionResponse<ServiceEntity>;
  banner_desktop: SingleMedia;
  banner_tablet: SingleMedia;
  banner_mini: SingleMedia;
  accordion_data: AccordionDataType[];
  priority: number;
  isArchived: boolean;
  shpd_id: number;
  offer_file?: SingleMedia;
}

export enum TARIFF_TYPE {
  ACTIVE = 'active',
  ARHIVE = 'archive',
}

const TARIFF_LABEL = {
  [TARIFF_TYPE.ACTIVE]: 'Актуальные',
  [TARIFF_TYPE.ARHIVE]: 'Архивные',
};

export const TariffTypeData = [
  { value: String(TARIFF_TYPE.ACTIVE), label: TARIFF_LABEL[TARIFF_TYPE.ACTIVE] },
  { value: String(TARIFF_TYPE.ARHIVE), label: TARIFF_LABEL[TARIFF_TYPE.ARHIVE] },
];

export enum TITLES {
  MOBILE = 'Мобильная связь',
  HOME_INTERNET = 'Домашний интернет',
  BEAUTIFUL_NUMBER = 'Красивый номер',
}
