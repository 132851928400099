import React, { useEffect, useState } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import { useDisclosure } from '@mantine/hooks';
import { UseFormReturnType } from '@mantine/form';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import cn from 'classnames';
import { Path } from '@/shared/constants/links';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { getMyTypeError } from '@/shared/lib/get-my-type-error';
import { getOrigin } from '@/shared/lib/base-query';
import { formatDateToDDmonthYYYY } from '@/shared/lib/utils';
import { storage } from '@/shared/lib/storage';
import { FormHeader } from '@/shared/ui-kit/form/header';
import { useCountBasketPrices } from '@/shared/lib/use-count-basket-prices';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Image, MainPageEntity } from '@/store/api/main-page/main-page.type';
import { TariffMobileEntity } from '@/store/api/tariff-mobile/tariff-mobile.type';
import { useCreateOrderMutation } from '@/store/api/order/order.api';
import { CreateOrderArgs } from '@/store/api/order/order.type';
import { TicketError } from '@/store/api/ticket/ticket.type';
import { resetBeautifulNumber } from '@/store/slice/beautiful-number';
import { ArticleEntity } from '@/store/api/article/article.type';
import { useBeautifulNumbers } from '@/shared/lib/use-beautiful-numbers';
import { ORDER_PARAMS } from '@/modules/tariff/shared/requests/constants';
import { useGetNumberCategoriesQuery } from '@/store/api/beautiful-numbers/beautiful-numbers.api';
import { TariffHomeInternetCategoryEntity } from '@/store/api/tariff-home-internet-category/tariff-home-internet-catogory.type';
import {
  changeBasePriceMobile,
  changeMobileTariff,
  changePromotionBasePriceMobile,
  changePromotionTotalPriceMobile,
  changeTotalPriceMobile,
  resetMobileTariffToInitial,
} from '@/store/slice/mobile-tariff.slice';
import { Carousel } from '@/widgets/carousel/carousel';
import { Esim } from '@/widgets/eSim/eSim';
import { Replenishment } from '@/widgets/replenishment/replenishment';
import { HomeInternet } from '@/widgets/home-internet';
import { VolnaSale } from '@/widgets/volna-sale/volna-sale';
import { BeautifulNumbers } from '@/widgets/beautiful-numbers/beautiful-numbers';
import { News } from '@/widgets/news/news';
import { TariffsCarousel } from '@/widgets/tariffs-carousel';
import { Form } from '@/widgets/form';
import { formatPhoneNumber } from '@/shared/lib/format-phone-number';
import { FormInitialValues } from '@/widgets/form/constants';
import { MOYA_STRANA } from '@/modules/tariff/shared/constants';
import { MainContent } from '@/modules/tariff/shared/basket/basket-main-content';
import { ResultModal } from '@/modules/tariff/shared/result-modal/result-modal';
import {
  mobileSliceMapper,
  mobileTariffResponseMapper,
  MobileTariffMappedOptions,
} from '@/modules/tariff/shared/helpers';
import { createOrderRequestMapper } from '@/modules/tariff/shared/requests/create-order-request';
import { reachGoal } from '@/shared/lib/ym';
import { YM } from '@/shared/constants/yandex-metrika';
import styles from './main-page.module.scss';

interface Props {
  tariffsMobile: TariffMobileEntity;
  tariffHomeInternetCategories: TariffHomeInternetCategoryEntity;
  mainPage: MainPageEntity;
  articles: ArticleEntity;
}

const MainPage: React.FC<Props> = ({
  tariffsMobile,
  tariffHomeInternetCategories,
  mainPage,
  articles,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { publicRuntimeConfig } = getConfig();
  const { isMobile } = useMediaQuery();
  const [currentTatiffData, setCurrentTatiffData] = useState<MobileTariffMappedOptions>();
  const [buildTimeHasShown, setBuildTimeHasShown] = useState(false);
  const [form, setForm] = useState<UseFormReturnType<FormInitialValues>>();
  const [formOpened, { open: openForm, close: closeForm }] = useDisclosure(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [resultModalTitle, setResultModalTitle] = useState('');
  const [resultModalInfo, setResultModalInfo] = useState('');
  const { selectedNumber } = useAppSelector((state) => state.beautifulNumber);
  const { totalBasketPrice, totalBasketPriceWithPromotion: totalBasketPriceWithPromotionNumber } =
    useCountBasketPrices();
  const { data: numberCategories } = useGetNumberCategoriesQuery({
    source_id: ORDER_PARAMS.SOURCE_ID,
  });

  const { numbers, isLoading: isNumbersLoading } = useBeautifulNumbers();

  const totalBasketPriceWithPromotion =
    totalBasketPriceWithPromotionNumber === totalBasketPrice ? undefined : totalBasketPriceWithPromotionNumber;

  const [
    createOrder,
    {
      data: createOrderData,
      isLoading: createOrderIsLoading,
      isError: createOrderIsError,
      error: createOrderError,
      isSuccess: createOrderIsSucces,
      reset: resetCreateOrder,
    },
  ] = useCreateOrderMutation();

  const banners =
    isMobile !== undefined
      ? mainPage?.data?.attributes?.banners?.map((i) => {
        const link = getLink(i) || '/';

        return {
          path: isMobile ? i.image_small?.data?.attributes?.url : i.image_large?.data?.attributes?.url,
          alt: isMobile ? i.image_small?.data?.attributes?.name : i.image_large?.data?.attributes?.name,
          href: link,
          mediaFormat: isMobile ? i.image_small?.data?.attributes?.mime : i.image_large?.data?.attributes?.mime,
        };
      })
      : [];

  const createOrderRequest = async (data: CreateOrderArgs) => {
    await createOrder(data).unwrap();
  };

  const formHandler = (form: UseFormReturnType<FormInitialValues>) => {
    reachGoal(YM.click_cart_step_final_main_page);
    reachGoal(`${YM.form_send_tariff_}${MOYA_STRANA.NAME}`);
    setForm(form);
    const salonWorkTime = form?.values.info.point?.split(' c ')[1] || '';

    if (currentTatiffData) {
      const result = createOrderRequest(
        createOrderRequestMapper({
          form,
          tariffName: currentTatiffData.localized_name,
          totalPrice: totalBasketPriceWithPromotion || totalBasketPrice,
          servicesIds: [],
          beautifulNumber: selectedNumber,
          trplId: currentTatiffData.trpl_id,
          work_time: salonWorkTime,
        }),
      );
    }
  };

  const handleCloseResultModal = () => {
    setShowResultModal(false);
    storage.remove('successfulPurchaseModalTitle');
    storage.remove('successfulPurchaseModalInfo');
    setResultModalTitle('');
    setResultModalInfo('');
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.delete('successful_purchase');
    router.push(newParams.toString());
    resetCreateOrder();
  };

  useEffect(() => {
    const currentTariff = mobileTariffResponseMapper(tariffsMobile).find(({ name }) => name === MOYA_STRANA.NAME);
    if (currentTariff) {
      setCurrentTatiffData(currentTariff);
      dispatch(changeMobileTariff(mobileSliceMapper(currentTariff)));
      dispatch(changeTotalPriceMobile(currentTariff.price));
      dispatch(changeBasePriceMobile(currentTariff.price));

      if (currentTariff.promotion_price) {
        dispatch(changePromotionTotalPriceMobile(currentTariff.promotion_price));
        dispatch(changePromotionBasePriceMobile(currentTariff.promotion_price));
      }

      return () => {
        dispatch(resetBeautifulNumber());
        dispatch(resetMobileTariffToInitial());
      };
    }
  }, [dispatch, tariffsMobile]);

  useEffect(() => {
    if (createOrderIsError) {
      const ticketError = getMyTypeError<TicketError>(createOrderError, 'status');
      closeForm();
      setResultModalTitle('Ошибка');
      const message = ticketError?.error || ticketError?.data?.message || ticketError?.data?.detail || '';
      setResultModalInfo(`${message}`);
      setShowResultModal(true);
    }
    if (createOrderIsSucces) {
      closeForm();
      storage.set(
        'successfulPurchaseModalTitle',
        `Красивый номер ${formatPhoneNumber(selectedNumber?.msisdn || '')} заказан`,
      );
      const redirectURL = getOrigin() + router.asPath;
      const url = createOrderData?.payment_system_response.payment_url + `?redirect_url=${redirectURL}`;
      url && router.push(url);
    }
  }, [
    router,
    createOrderIsError,
    createOrderIsSucces,
    createOrderError,
    createOrderData?.payment_system_response.payment_url,
    currentTatiffData,
    closeForm,
    selectedNumber?.msisdn,
  ]);

  useEffect(() => {
    if (!buildTimeHasShown) {
      // Этот console.log не удаляйте!
      console.log(`Build time [${publicRuntimeConfig.BUILD_TIME}]`);
      setBuildTimeHasShown(true);
    }

    // Убираем выбранный КН когда переходим н адругую страницу
    return () => {
      dispatch(resetBeautifulNumber());
    };
  }, []);

  useEffect(() => {
    if (router.query.successful_purchase) {
      setShowResultModal(true);
    }
  }, [router.query.successful_purchase]);

  useEffect(() => {
    if (router.query.successful_purchase) {
      return;
    }

    if (form?.values.delivery === 'true') {
      storage.set(
        'successfulPurchaseModalInfo',
        `Оператор свяжется с вами ${form?.values.info.exactTime === 'true'
          ? formatDateToDDmonthYYYY(form?.values.info.date)
          : 'в течение 15 минут'
        } для уточнения даты доставки SIM-карты с красивым номером.`,
      );
    } else {
      storage.set(
        'successfulPurchaseModalInfo',
        `SIM-карта с красивым номером будет ждать вас в отделении Волны по адресу ${form?.values.info.point}. Возьмите с собой паспорт или загранпаспорт для удостоверения личности.`,
      );
    }
  }, [
    form?.values.info.exactTime,
    form?.values.info.date,
    form?.values.info.point,
    form?.values.delivery,
    router.query.successful_purchase,
  ]);

  return (
    <div className={cn(styles.wrapper, 'main-page')}>
      {banners && banners.length ? (
        <div className={styles.carouselWrapper}>
          <Carousel images={banners} />
        </div>
      ) :
        null
      }
      {tariffsMobile && (
        <div className={cn(styles.tariffs, 'tariffs')}>
          <TariffsCarousel tariffsMobile={tariffsMobile} tariffHomeInternetCategories={tariffHomeInternetCategories} />
        </div>
      )}
      <div className={styles.banners}>
        <BeautifulNumbers
          beautifulNumbers={numbers}
          className={styles.banners__beautiful_numbers}
          onShowForm={() => openForm()}
          activeTariffData={currentTatiffData}
          numberCategories={numberCategories}
          page={'main'}
          isLoading={isNumbersLoading}
        />
        <VolnaSale className={styles.banners__volna_sale} />
        <Esim className={styles.banners__esim} />
        <Replenishment />
        {/* <ReplenishmentBanner className={styles.banners__replenishment} /> */}
        <HomeInternet className={styles.banners__home_internet} />
      </div>
      <div className={styles.news}>
        <News articles={articles} />
      </div>
      <Form
        totalPrice={totalBasketPriceWithPromotion || totalBasketPrice}
        isOpened={formOpened}
        onClose={closeForm}
        confirmPanel={
          currentTatiffData ? (
            <MainContent
              totalBasketPrice={totalBasketPrice}
              totalBasketPriceWithPromotion={totalBasketPriceWithPromotion}
              isMainPage
            />
          ) : null
        }
        header={
          <FormHeader
            beautifulNumber={formatPhoneNumber(selectedNumber?.msisdn || '')}
            localized_name={currentTatiffData?.localized_name || ''}
            totalPrice={totalBasketPriceWithPromotion || totalBasketPrice}
          />
        }
        handler={formHandler}
        requestIsLoading={createOrderIsLoading}
      />
      <ResultModal
        opened={showResultModal}
        close={handleCloseResultModal}
        title={router.query.successful_purchase ? storage.get('successfulPurchaseModalTitle') : resultModalTitle}
        info={router.query.successful_purchase ? storage.get('successfulPurchaseModalInfo')! : resultModalInfo}
      />
    </div>
  );
};

function getLink(banner: Image) {
  if (banner?.link) {
    return banner.link;
  }
  if (banner?.tariff?.data) {
    return `${Path.tariffMobile.root}/${banner.tariff.data.attributes.name}`;
  }
  if (banner?.article?.data) {
    return `${Path.news}/${banner.article.data.id}`;
  }
  if (banner?.service_category?.data?.attributes) {
    return `${Path.services.root}/${banner.service_category.data.attributes.name}`;
  }
}

export default MainPage;
